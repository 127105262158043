import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import LoginForm from "./components/login/LoginForm";
import firebase from "./firebase";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");
const renderApp = user => {
  ReactDOM.render(<App user={user} />, root);
};
const renderLogin = () => {
  ReactDOM.render(<LoginForm />, root);
};

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    renderApp(user);
  } else {
    renderLogin();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
