import React, { useState } from 'react'
import { Drawer, Divider, Col, Row, Button } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const pStyle = {
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'inline-block',
  marginBottom: 16,
}

const DescriptionItem = ({ title, content, imported = false }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
      color: 'rgba(0,0,0,0.65)',
    }}
  >
    <p
      style={{
        marginRight: 8,
        display: 'inline-block',
        color: 'rgba(0,0,0,0.85)',
      }}
    >
      {title}:
    </p>
    {content}
    {imported && <small> (* importado)</small>}
  </div>
)

const QuoteResume = ({
  visible,
  handleClose,
  serviceType,
  company,
  agent,
  name,
  serviceNumber,
  brand,
  elements,
  reference,
  serie,
  generatePdf,
  loading,
  observations,
}) => {
  const [copied, setCopied] = useState(false)
  const formatNumber = number => {
    if (!number || isNaN(number)) {
      return '$ 0'
    } else {
      return `$ ${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  const serviceString = elementsArray => {
    let elementsString = ''
    let optionalsString = ''
    elementsArray.forEach(element => {
      if (element.optional) {
        if (element.isImported) {
          optionalsString += `(${element.elementName} = ${formatNumber(
            element.elementValue
          )} importado), `
        } else {
          optionalsString += `(${element.elementName} = ${formatNumber(
            element.elementValue
          )}), `
        }
      } else {
        if (element.isImported) {
          elementsString += `(${element.elementName} = ${formatNumber(
            element.elementValue
          )} importado), `
        } else {
          elementsString += `(${element.elementName} = ${formatNumber(
            element.elementValue
          )}), `
        }
      }
    })
    return {
      elementsString,
      optionalsString,
    }
  }
  const text = `Tipo de servicio: ${serviceType}, Servicio: ${
    serviceString(elements).elementsString
  } Opcionales: ${
    serviceString(elements).optionalsString
      ? serviceString(elements).optionalsString
      : 'No hay opcionales,'
  } Observaciones: ${observations ? observations : 'No hay observaciones'}`
  const optionals = elements.filter(element => element.optional === true)
  return (
    <div>
      <Drawer
        width={840}
        placement="right"
        closable={true}
        onClose={handleClose}
        visible={visible}
        destroyOnClose={true}
      >
        <div style={{ width: 330 }}>
          <h3 style={{ ...pStyle, fontWeight: 'bold' }}>Resumen Cotizacion</h3>
          <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
            <Button ghost type="primary" icon="copy" style={{ float: 'right' }}>
              {copied ? '¡Copiado!' : 'Copiar Resumen'}
            </Button>
          </CopyToClipboard>
        </div>
        <p style={pStyle}>Informacion General</p>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Tipo de servicio" content={serviceType} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Empresa que factura" content={company} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Asesor encargado" content={agent} />
          </Col>
        </Row>
        <Divider />
        <p style={pStyle}>Informacion del Cliente</p>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Nombre" content={name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Numero del caso" content={serviceNumber} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Marca del reloj" content={brand} />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="Referencia"
              content={reference ? reference : 'No tiene referencia'}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Serie"
              content={serie ? serie : 'No tiene serie'}
            />
          </Col>
        </Row>
        <Divider />
        <p style={pStyle}>Informacion del Servicio</p>

        <Row>
          {elements
            .filter(element => element.optional === false)
            .map(element => (
              <Col span={12} key={element.id}>
                <DescriptionItem
                  title={element.elementName}
                  content={formatNumber(element.elementValue)}
                  imported={element.isImported}
                />
              </Col>
            ))}
        </Row>
        <Divider />
        <p style={pStyle}>Adicionales (Opcionales)</p>
        <Row>
          {optionals.length > 0
            ? optionals.map(element => (
                <Col span={12} key={element.id}>
                  <DescriptionItem
                    title={element.elementName}
                    content={formatNumber(element.elementValue)}
                    imported={element.isImported}
                  />
                </Col>
              ))
            : 'No hay opcionales'}
        </Row>
        <Button
          type="primary"
          size="large"
          className="download-button"
          onClick={generatePdf}
          loading={loading}
        >
          Generar Cotizacion
        </Button>
      </Drawer>
    </div>
  )
}

export default QuoteResume
