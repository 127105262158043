import React from "react";
import "./App.css";
import headerLogo from "./assets/images/logo-joyeria-blanco-500.png";
import firebase from "./firebase";
import { Layout, Row, Col, Button } from "antd";
import Main from "./components/Main";
const { Header, Content } = Layout;

const App = ({ user }) => {
  return (
    <Layout>
      <Header className="main-menu">
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ marginTop: 8 }}
        >
          <Col span={8}> </Col>
          <Col span={8}>
            <img src={headerLogo} alt="logo joyeria" className="header-logo" />
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              style={{
                float: "right"
              }}
              onClick={() => firebase.auth().signOut()}
            >
              Salir
            </Button>
          </Col>
        </Row>
      </Header>
      <Content style={{ padding: "0 30px" }}>
        <Row type="flex" justify="center" style={{ paddingTop: 30 }}>
          <Col span={24}>
            <h1 style={{ textAlign: "center" }}>Bienvenido a InterQuote</h1>
          </Col>
        </Row>

        <Main />
      </Content>
    </Layout>
  );
};

export default App;
