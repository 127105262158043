import app from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyC00BwUOuUk3d-PM1olumuONhCrGbkcfqU',
  authDomain: 'cotizador-inter.firebaseapp.com',
  projectId: 'cotizador-inter',
  storageBucket: 'cotizador-inter.appspot.com',
  messagingSenderId: '768043350326',
  appId: '1:768043350326:web:092fb3606283e91c4b5b97',
  measurementId: 'G-6273ENBBEB',
}

export default app.initializeApp(config)
