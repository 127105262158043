import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  AutoComplete,
  Radio,
  Modal,
} from 'antd'
import ElementsSet from './ElementsSet'
import QuoteResume from './QuoteResume'
const { TextArea } = Input
const { Option, OptGroup } = Select

const Main = () => {
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [loading, setLoading] = useState(true)
  const [companies, setCompanies] = useState([])
  const [elements, setElements] = useState([])
  const [name, setName] = useState('')
  const [treatment, setTreatment] = useState('Sr.')
  const [currentAgent, setCurrentAgent] = useState({})
  const [agent, setAgent] = useState('')
  const [brand, setBrand] = useState('')
  const [serviceNumber, setServiceNumber] = useState('')
  const [agents, setAgents] = useState([])
  const [brands, setBrands] = useState([])
  const [reference, setReference] = useState('')
  const [serie, setSerie] = useState('')
  const [company, setCompany] = useState('Joyeria Intercontinental')
  const [observations, setObservations] = useState('')
  const [serviceType, setServiceType] = useState('RAPIDO')
  const [resumeVisible, setResumeVisible] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const [rawBrands, rawAgents] = await axios.all([
        axios(
          `https://script.google.com/macros/s/AKfycbzNpqoEiPZMYGQZvVHMfb_AqLbhqE3xZdcMrwUwpd45e8GgGfxT/exec?resource=brands`
        ),
        axios(
          `https://script.google.com/macros/s/AKfycbzNpqoEiPZMYGQZvVHMfb_AqLbhqE3xZdcMrwUwpd45e8GgGfxT/exec?resource=agents`
        ),
      ])
      setBrands(rawBrands.data.data)
      setAgents(rawAgents.data.data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (agents.length > 0) {
      setLoading(false)
    }
    setCompanies(getCompanies(agents))
  }, [agents])

  useEffect(() => {
    if (agents.length > 0 && agent.length > 0) {
      const currentAgent = agents.filter(
        currentAgent => currentAgent.name === agent
      )
      setCurrentAgent(currentAgent[0])
      setCompany(currentAgent[0].company)
    }
  }, [agent])

  const getCompanies = agents => {
    const allCompanies = agents.map(agent => agent.company)
    return [...new Set(allCompanies)]
  }
  const getAgentsPerCompany = company => {
    return agents.filter(agent => agent.company === company)
  }
  const handleElementsChange = elements => {
    setElements(elements)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (
      !name ||
      !treatment ||
      !currentAgent ||
      !brand ||
      !company ||
      !observations ||
      !serviceType ||
      !elements ||
      !serviceNumber
    )
      return warning(
        'Todos los campos, con excepcion de referencia y serie son obligatorios',
        () => setResumeVisible(false)
      )
    setResumeVisible(true)
  }
  const generatePdf = async () => {
    if (
      !name ||
      !treatment ||
      !currentAgent ||
      !brand ||
      !company ||
      !observations ||
      !serviceType ||
      !elements ||
      !serviceNumber
    )
      return error(
        'Todos los campos, con excepcion de referencia y serie son obligatorios',
        () => setResumeVisible(false)
      )

    setLoadingPdf(true)
    const url =
      'https://script.google.com/macros/s/AKfycbzNpqoEiPZMYGQZvVHMfb_AqLbhqE3xZdcMrwUwpd45e8GgGfxT/exec'
    const serviceValue = elements
      .filter(e => e.elementName)
      .map(element => ({
        name: element.elementName,
        value: element.elementValue || 0,
        optional: element.optional,
        imported: element.isImported,
      }))

    const request = {
      name,
      date: `${new Date().getDate()}/${new Date().getMonth() +
        1}/${new Date().getFullYear()}`,
      treatment,
      treatmentGreeting: treatment === 'Sr.' ? 'Estimado' : 'Estimada',
      agentId: currentAgent.id,
      brand,
      reference,
      serie,
      company,
      observations,
      serviceType,
      serviceValue,
      serviceNumber,
    }

    const headers = {
      'Content-Type': 'text/plain;charset=utf-8',
    }

    try {
      const response = await axios.post(url, request, {
        headers,
      })
      if (response.data.status === 'success') {
        success(
          'Hemos generado la cotizacion y se ha enviado al asesor con exito',
          handleFinish
        )
      } else {
        error(response.data.data.message, () => setResumeVisible(false))
      }

      console.log(response.data)
    } catch (e) {
      error(e.message)
      console.log(e)
    }
    setLoadingPdf(false)
  }

  const success = (message, onOk) => {
    Modal.success({
      title: 'Cotizacion generada con exito',
      content: message,
      onOk: onOk,
    })
  }

  const error = (message, onOk) => {
    Modal.error({
      title: 'Error al generar la cotizacion',
      content: message,
      onOk: onOk,
    })
  }

  const warning = (message, onOk) => {
    Modal.warning({
      title: 'Tenemos inconsistencias',
      content: message,
      onOk: onOk,
    })
  }

  const handleFinish = () => {
    setElements([])
    setName('')
    setTreatment('Sr.')
    // setCurrentAgent({})
    // setAgent('')
    setBrand('')
    setServiceNumber('')
    setReference('')
    setSerie('')
    setCompany('Joyeria Intercontinental')
    setObservations('')
    setServiceType('RAPIDO')
    setResumeVisible(false)
  }

  return (
    <div className="main">
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Tratamiento cliente">
              <Select
                defaultValue={treatment}
                onChange={value => setTreatment(value)}
              >
                <Option value="Sr.">Sr.</Option>
                <Option value="Sra.">Sra.</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Nombre del Cliente">
              <Input
                placeholder="Nombre cliente"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Asesor">
              <Select
                placeholder="Buscar el asesor correspondiente"
                showSearch
                style={{ width: '100%' }}
                onChange={value => setAgent(value)}
                loading={loading}
              >
                {companies.map(company => (
                  <OptGroup key={company} label={company}>
                    {getAgentsPerCompany(company).map(agent => (
                      <Option key={agent.id} value={agent.name}>
                        {agent.name}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Empresa que factura">
              <Select
                style={{ width: '100%' }}
                onChange={value => setCompany(value)}
                value={company}
                loading={loading}
              >
                {companies.map(company => (
                  <Option key={company} value={company}>
                    {company}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Numero del servicio">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Numero del caso"
                onChange={value => setServiceNumber(value)}
                value={serviceNumber}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <Form.Item label="Marca">
                  <AutoComplete
                    dataSource={brands}
                    placeholder="Busca la marca correspondiente"
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={value => setBrand(value)}
                    onSelect={value => setBrand(value)}
                    value={brand}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item label="Referencia">
                  <Input
                    placeholder="Referencia"
                    onChange={e => setReference(e.target.value)}
                    value={reference}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item label="Serie">
                  <Input
                    placeholder="Serie"
                    onChange={e => setSerie(e.target.value)}
                    value={serie}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Tipo de servicio">
              <Radio.Group
                defaultValue="RAPIDO"
                buttonStyle="solid"
                onChange={e => setServiceType(e.target.value)}
                value={serviceType}
              >
                <Radio.Button value="RAPIDO">Rapido</Radio.Button>
                <Radio.Button value="MANTENIMIENTO">Mantenimiento</Radio.Button>
                <Radio.Button value="COMPLETO">Completo</Radio.Button>
                <Radio.Button value="ROLEX">Rolex</Radio.Button>
                <Radio.Button value="RECAMBIO">Recambio</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Observaciones">
              <TextArea
                onChange={e => setObservations(e.target.value)}
                value={observations}
                rows={3}
                placeholder="Agrega informacion adicional importante"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Elementos de la cotizacion">
              <ElementsSet
                elements={elements}
                changeElements={handleElementsChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            style={{ marginTop: 30 }}
          >
            Revisar y enviar Cotizacion
          </Button>
        </Form.Item>
      </Form>
      <QuoteResume
        visible={resumeVisible}
        handleClose={() => setResumeVisible(false)}
        serviceType={serviceType}
        company={company}
        agent={agent}
        name={name}
        serviceNumber={serviceNumber}
        brand={brand}
        elements={elements.filter(e => e.elementName)}
        reference={reference}
        serie={serie}
        generatePdf={generatePdf}
        loading={loadingPdf}
        observations={observations}
      />
    </div>
  )
}

export default Main
