import React from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Card,
  Layout,
  message
} from "antd";
import firebase from "../../firebase";
import "./Login.css";
import headerLogo from "../../assets/images/logo-joyeria.png";

const { Content } = Layout;

class NormalLoginForm extends React.Component {
  state = {
    isLoading: false
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return this.setState({ isLoading: false });
      }
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(values.userName, values.password);
      } catch (e) {
        message.error(e.message);
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout style={{ height: "100vh" }}>
        <Content>
          <Row type="flex" justify="center" align="middle">
            <Col xs={20} sm={16} md={12} lg={8} xl={6}>
              <Card
                style={{ marginTop: 100 }}
                cover={
                  <img
                    src={headerLogo}
                    alt="Inter Logo"
                    className="login-logo"
                  />
                }
              >
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <Form.Item>
                    {getFieldDecorator("userName", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your username!"
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Usuario"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Password!"
                        }
                      ]
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Contraseña"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={this.state.isLoading}
                    >
                      Ingresar
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const LoginForm = Form.create({ name: "normal_login" })(NormalLoginForm);

export default LoginForm;
