import React from "react";
import {
  Input,
  Icon,
  Button,
  Checkbox,
  InputNumber,
  Row,
  Col,
  Divider
} from "antd";

let currentKey = 0;
const ElementsSet = ({ elements, changeElements }) => {
  const handleAddElement = () => {
    currentKey++;
    const newElements = elements.concat([
      {
        id: currentKey,
        elementName: "",
        elementValue: "",
        optional: false,
        isImported: false
      }
    ]);
    changeElements(newElements);
  };

  const handleRemoveElement = idx => () => {
    const newElements = elements.filter((element, eidx) => idx !== eidx);
    changeElements(newElements);
  };

  const handleElementNameChange = idx => e => {
    const newElements = elements.map((element, eidx) => {
      if (idx !== eidx) return element;
      return { ...element, elementName: e.target.value };
    });
    changeElements(newElements);
  };

  const handleElementValueChange = idx => value => {
    const newElements = elements.map((element, eidx) => {
      if (idx !== eidx) return element;
      return { ...element, elementValue: value };
    });
    changeElements(newElements);
  };
  const handleElementOptionalChange = idx => e => {
    const newElements = elements.map((element, eidx) => {
      if (idx !== eidx) return element;
      return { ...element, optional: e.target.checked };
    });
    changeElements(newElements);
  };
  const handleElementIsImportedChange = idx => e => {
    const newElements = elements.map((element, eidx) => {
      if (idx !== eidx) return element;
      return { ...element, isImported: e.target.checked };
    });
    changeElements(newElements);
  };
  const elementsFileds = elements.map((element, index) => {
    return (
      <Row type='flex' key={element.id}>
        <Col span={24}>
          <Input
            placeholder='Nombre del repuesto'
            style={{ maxWidth: 250, marginRight: 8 }}
            value={element.elementName}
            onChange={handleElementNameChange(index)}
          />
          <InputNumber
            placeholder='Precio'
            min={0}
            max={1000000000}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            style={{ width: 150, marginRight: 8, marginLeft: 8 }}
            onChange={handleElementValueChange(index)}
          />
          <Checkbox
            style={{ marginRight: 8 }}
            checked={element.optional}
            onChange={handleElementOptionalChange(index)}
          >
            Opcional
          </Checkbox>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={element.isImported}
            onChange={handleElementIsImportedChange(index)}
          >
            Importado
          </Checkbox>
          <Icon
            className='dynamic-delete-button'
            type='minus-circle-o'
            onClick={handleRemoveElement(index)}
          />
        </Col>
        <Divider />
      </Row>
    );
  });

  return (
    <>
      {elementsFileds}
      <Button
        type='dashed'
        onClick={handleAddElement}
        style={{ minWidth: "150px", marginBottom: 10 }}
      >
        <Icon type='plus' /> Agregar Repuesto
      </Button>
    </>
  );
};

export default ElementsSet;
